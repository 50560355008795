import { StateType } from '../../enums/StateType';
export var getFirstErrorMessage = function (errorsTypes) {
    return errorsTypes && Object.keys(errorsTypes).length > 0
        ? [
            {
                message: Object.values(errorsTypes)[0],
                type: StateType.Error,
            },
        ]
        : [];
};
