var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Input from './index';
export var InputWithReplace = function (props) {
    var replace = props.replace, onChange = props.onChange, restProps = __rest(props, ["replace", "onChange"]);
    var handleChange = function (event) {
        var updatedEvent = event;
        if (typeof replace === 'function') {
            var replacedValue = replace(event.target.value);
            updatedEvent = __assign(__assign({}, event), { target: __assign(__assign({}, event.target), { value: replacedValue }) });
        }
        onChange(updatedEvent);
    };
    return React.createElement(Input, __assign({}, restProps, { onChange: handleChange }));
};
export var InputWithFormat = function (props) {
    var format = props.format, unformat = props.unformat, value = props.value, onChange = props.onChange, restProps = __rest(props, ["format", "unformat", "value", "onChange"]);
    var handleChange = function (event) {
        var updatedEvent = event;
        var unformattedValue = unformat(event.target.value);
        updatedEvent = __assign(__assign({}, event), { target: __assign(__assign({}, event.target), { value: unformattedValue }) });
        onChange(updatedEvent);
    };
    return React.createElement(Input, __assign({}, restProps, { value: format(value), onChange: handleChange }));
};
