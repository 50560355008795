var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LENGTH_POSTAL_CODE } from '../constants/form';
import { Region } from '../enums/Region';
import { isLocaleRegion } from './region';
var REGEX_4_DIGITS_2_LETTERS = /([\d]{4})([\w]{2})/;
var REGEX_ONLY_DIGITS = /^\d*$/;
export var netherlandsZipCodeReplace = function (value) {
    return value.replace(REGEX_4_DIGITS_2_LETTERS, '$1 $2').toUpperCase();
};
export var getCommonZipInputProps = function (formRegion) { return (__assign(__assign({}, (LENGTH_POSTAL_CODE.has(formRegion)
    ? { maxLength: LENGTH_POSTAL_CODE.get(formRegion) }
    : null)), (formRegion === Region.Nl
    ? {
        replace: netherlandsZipCodeReplace,
    }
    : isLocaleRegion(formRegion)
        ? {
            regex: REGEX_ONLY_DIGITS,
        }
        : null))); };
