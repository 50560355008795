export var AddressCountryCode;
(function (AddressCountryCode) {
    AddressCountryCode["CH"] = "CH";
    AddressCountryCode["BE"] = "BE";
    AddressCountryCode["BG"] = "BG";
    AddressCountryCode["DK"] = "DK";
    AddressCountryCode["DE"] = "DE";
    AddressCountryCode["EE"] = "EE";
    AddressCountryCode["FI"] = "FI";
    AddressCountryCode["FR"] = "FR";
    AddressCountryCode["GR"] = "GR";
    AddressCountryCode["IE"] = "IE";
    AddressCountryCode["IT"] = "IT";
    AddressCountryCode["HR"] = "HR";
    AddressCountryCode["LV"] = "LV";
    AddressCountryCode["LT"] = "LT";
    AddressCountryCode["LU"] = "LU";
    AddressCountryCode["MT"] = "MT";
    AddressCountryCode["NL"] = "NL";
    AddressCountryCode["AT"] = "AT";
    AddressCountryCode["PL"] = "PL";
    AddressCountryCode["PT"] = "PT";
    AddressCountryCode["RO"] = "RO";
    AddressCountryCode["SE"] = "SE";
    AddressCountryCode["SK"] = "SK";
    AddressCountryCode["SI"] = "SI";
    AddressCountryCode["ES"] = "ES";
    AddressCountryCode["CZ"] = "CZ";
    AddressCountryCode["HU"] = "HU";
    AddressCountryCode["CY"] = "CY";
    AddressCountryCode["NO"] = "NO";
    AddressCountryCode["LI"] = "LI";
    AddressCountryCode["IS"] = "IS";
})(AddressCountryCode || (AddressCountryCode = {}));
