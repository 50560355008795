var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { borderRight, fontSize, height, lineHeight, minWidth, space, themeGet, } from 'styled-system';
import { InputType } from '../../enums/input-type';
import { useLocalization } from '../../providers/LocaleProvider';
import { borderRadiusBL, borderRadiusBR, borderRadiusTL, borderRadiusTR, } from '../../theme/system-utilities';
import { getFirstErrorMessage } from '../../utils/form/error-message';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { InputWithReplace } from '../Input/StatefulInput/InputWithReplace';
import StateMessages from '../Input/StatefulInput/StateMessages';
import { BodyRegularBR1 } from '../Typography';
var InputContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  svg {\n    top: 8px;\n  }\n"], ["\n  ", ";\n  svg {\n    top: 8px;\n  }\n"])), minWidth);
var Input = styled(InputWithReplace)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    border-color: ", ";\n  }\n  &:focus-visible {\n    outline-offset: -2px;\n  }\n  &:not(:focus)[value=''] + label {\n    line-height: 28px;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    border-color: ", ";\n  }\n  &:focus-visible {\n    outline-offset: -2px;\n  }\n  &:not(:focus)[value=''] + label {\n    line-height: 28px;\n  }\n"])), height, borderRight, borderRadiusTR, borderRadiusBR, themeGet('colors.sys.neutral.border.hover'));
var inputProps = {
    mb: 'sp_0',
    height: '48px',
    width: '183px',
    type: InputType.Text,
    borderRight: 'none',
    borderRadiusTR: 0,
    borderRadiusBR: 0,
};
var SubmitButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  > div {\n    align-items: center;\n  }\n  &:focus-visible {\n    outline-offset: -2px;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  > div {\n    align-items: center;\n  }\n  &:focus-visible {\n    outline-offset: -2px;\n  }\n"])), minWidth, space, fontSize, lineHeight, borderRadiusBL, borderRadiusTL);
var submitButtonStyles = {
    width: 'fit-content',
    minWidth: '105px',
    color: 'sys.neutral.text.default',
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: 'lh_28',
    borderRadiusBL: 0,
    borderRadiusTL: 0,
};
var ZipCodeField = function (_a) {
    var formState = _a.formState, errorsTypes = _a.errorsTypes, value = _a.value, inputMode = _a.inputMode, onChange = _a.onChange, replace = _a.replace, buttonLabel = _a.buttonLabel;
    var t = useLocalization().t;
    var isSubmitting = formState.isSubmitting;
    var firstErrorMessage = getFirstErrorMessage(errorsTypes);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyRegularBR1, null, t('h24_enter_zip_code_helper_text')),
        React.createElement(InputContainer, { minWidth: [1, '288px'], mt: "sp_16" },
            React.createElement(Input, __assign({}, inputProps, (replace && { replace: replace }), (inputMode && { inputMode: inputMode }), { onChange: onChange, hasError: !!errorsTypes, value: value !== null && value !== void 0 ? value : '', label: t('h24_zip_code'), autoFocus: true })),
            React.createElement(SubmitButton, __assign({}, submitButtonStyles, { isLoading: isSubmitting, kind: "secondary", type: "submit" }), buttonLabel)),
        !!(firstErrorMessage === null || firstErrorMessage === void 0 ? void 0 : firstErrorMessage.length) && React.createElement(StateMessages, { messages: firstErrorMessage })));
};
export default ZipCodeField;
var templateObject_1, templateObject_2, templateObject_3;
