import { Region } from '../../common/enums/Region';
import { AddressCountryCode } from '../enums/AddressCountryCode';
export var getCountryCodeFromRegion = function (region) {
    var _a;
    return (_a = {},
        _a[Region.At] = AddressCountryCode.AT,
        _a[Region.Be] = AddressCountryCode.BE,
        _a[Region.Ch] = AddressCountryCode.CH,
        _a[Region.De] = AddressCountryCode.DE,
        _a[Region.Fr] = AddressCountryCode.FR,
        _a[Region.It] = AddressCountryCode.IT,
        _a[Region.Nl] = AddressCountryCode.NL,
        _a)[region];
};
export var isCorrectCountryCode = function (countryCode) {
    return Object.values(AddressCountryCode).includes(countryCode);
};
export var isCountryCodeNl = function (countryCode) {
    return countryCode === AddressCountryCode.NL;
};
